import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Assuming useAuth provides isAuthenticated
  console.log(isAuthenticated);
  if (!isAuthenticated) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  return children; // Render the children if authenticated
};
