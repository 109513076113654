import React from 'react';
import ThemeCustomization from 'themes';
import Loadable from 'components/Loadable';
import AccountProvider from 'context/AccountProvider';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AdminUserProvider } from 'store/api/adminApi';
import { ContactsProvider } from 'store/api/contactApi';
import { UsersProvider } from 'store/api/usersApi';
import { BulkActionsProvider } from './store/api/bulkActionsApi';
import { AppProvider } from 'context/AppContext';
import { AuthProvider } from './hooks/useAuth';
import { AlertProvider } from 'hooks/useAlert';
import { LoadingProvider } from 'hooks/useLoading';
import { ProtectedRoute } from 'routes/ProtectedRoute';

import CallbackPage from 'pages/authentication/oauthcallbackredirectpage';
import ValidateCallback from 'pages/authentication/validatecallback';

import GoogleCalendarCallback from 'pages/authentication/GoogleCalendarCallback';

import SuccessPage from 'pages/settings/Success';
import UserList from 'pages/adminPanel/users/usersList';
import Dashboard from 'pages/adminPanel/users/Dashboard';
import SupportTicket from 'pages/adminPanel/SupportTicket/TicketList';
import DebugCenter from 'pages/adminPanel/DebugCenter/debugLogList';
import ServerLog from 'pages/adminPanel/serverlogs/serverLogs';
import RuntimeLog from 'pages/adminPanel/runtimeErrors/runtimeErrors';
import AccountSetting from 'components/templates/chat/AccountSetting';
import SupportSetting from 'components/templates/chat/SupportSetting';
import EmergencySetting from 'components/templates/chat/EmergencySetting';
import Docs from 'components/templates/chat/Docs';
import TestMessages from 'components/agents/agents/TestMessages';
import { lazy } from 'react';
import CheckoutPage from 'pages/payment';
import Chatbot from 'components/chatbot/Chatbot';
import Analytics from 'pages/Analytics/Analytics';

const LandingPage = Loadable(lazy(() => import('pages/landing/landing')));
const NewLanding = Loadable(lazy(() => import('pages/NewLanding/Landing')));
const NotFound = Loadable(lazy(() => import('pages/settings/NotFound')));

const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthSignup = Loadable(lazy(() => import('pages/authentication/Register')));
const AgentsPage = Loadable(lazy(() => import('pages/agents/AgentsPage')));
const ContactsPage = Loadable(lazy(() => import('pages/chats/ContactsPage')));
const SettingsPage = Loadable(lazy(() => import('pages/settings/SettingsPage')));
const AgentsLayout = Loadable(lazy(() => import('pages/agents/AgentsLayout')));
const UsersLayout = Loadable(lazy(() => import('layouts/UsersLayout/index')));
const AdminPanel = Loadable(lazy(() => import('pages/adminPanel/index')));

const compose = (providers) =>
  providers.reduce((Prev, Curr) => {
    const WrappedComponent = ({ children }) => {
      return (
        <Prev>
          <Curr>{children}</Curr>
        </Prev>
      );
    };

    WrappedComponent.displayName = `Compose(${Prev.displayName || Prev.name}, ${Curr.displayName || Curr.name})`;

    return WrappedComponent;
  });

const Provider = compose([
  ContactsProvider,
  AppProvider,
  AdminUserProvider,
  BulkActionsProvider,
  AccountProvider,
  BulkActionsProvider,
  UsersProvider
]);

const App = () => (
  <ThemeCustomization>
    <AlertProvider>
      <LoadingProvider>
        <AuthProvider>
          <Provider>
            <Chatbot key="chatbot" apiUrl="https://api.yourdomain.com/chatbot" />
            {/* <VoiceflowChat /> */}
            <Routes>
              {/* <Route element={<Chatbot apiUrl="https://api.yourdomain.com/chatbot" />} path="/livechats" /> */}
              <Route element={<LandingPage />} path="/landing" />
              <Route element={<NewLanding />} path="/newlanding" />

              <Route element={<CheckoutPage />} path="/checkout" />

              <Route element={<SuccessPage />} path="/success" />
              <Route element={<CallbackPage />} path="/calendly/callback" />
              <Route element={<ValidateCallback />} path="/GoHigh/callback" />
              <Route element={<GoogleCalendarCallback />} path="/GoogleCalendar/callback" />
              <Route element={<AdminPanel />} path="/adminPanel">
                <Route element={<Dashboard />} path="dashboard" />
                <Route element={<UserList />} path="users" />
                <Route element={<SupportTicket />} path="support" />
                <Route element={<DebugCenter />} path="debug" />
                <Route element={<ServerLog />} path="serverlog" />
                <Route element={<RuntimeLog />} path="runtimeErrors" />
              </Route>
              <Route element={<Login />} path="/login" />
              <Route element={<AuthSignup />} path="/signup" />
              <Route element={<UsersLayout />}>
                <Route
                  element={
                    <ProtectedRoute>
                      <Docs />
                    </ProtectedRoute>
                  }
                  path="docs"
                />

                <Route
                  element={
                    <ProtectedRoute>
                      <Analytics />
                    </ProtectedRoute>
                  }
                  path="analytics"
                />

                <Route element={<Navigate to="/contacts" />} path="/" />
                <Route
                  element={
                    <ProtectedRoute>
                      <ContactsPage />
                    </ProtectedRoute>
                  }
                  path="/contacts"
                >
                  <Route
                    element={
                      <ProtectedRoute>
                        <ContactsPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route element={<AgentsPage />} path="agents">
                  <Route
                    element={
                      <ProtectedRoute>
                        <AgentsLayout />
                      </ProtectedRoute>
                    }
                    path=":agentId"
                  >
                    <Route element={<TestMessages />} path="demo" />
                  </Route>
                </Route>
                <Route element={<SettingsPage />} path="/settings">
                  <Route
                    element={
                      <ProtectedRoute>
                        <AccountSetting />
                      </ProtectedRoute>
                    }
                    path="accounts"
                  />
                  <Route
                    element={
                      <ProtectedRoute>
                        <SupportSetting />
                      </ProtectedRoute>
                    }
                    path="support"
                  />
                  <Route
                    element={
                      <ProtectedRoute>
                        <EmergencySetting />
                      </ProtectedRoute>
                    }
                    path="emergency"
                  />
                  <Route
                    element={
                      <ProtectedRoute>
                        <Analytics />
                      </ProtectedRoute>
                    }
                    path="analytics"
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Provider>
        </AuthProvider>
      </LoadingProvider>
    </AlertProvider>
  </ThemeCustomization>
);

export default App;
