import React, { createContext, useState, useContext, useEffect } from 'react';

import axiosRequest from 'utils/axiosRequest';
import { useNavigate } from 'react-router-dom';
import ErrorModal from 'components/globalerror';
import config from '../../config';
import { isEqual } from 'lodash';
import { useLocalStorage } from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';

const defaultContacts = [
  {
    data: {
      AI_typing: 0,
      active: 0,
      active_agent_name: null,
      active_tools: [],
      conversation: [
        {
          content: 'chris guerrette just entered the chat',
          hide: false,
          platform: 'System',
          role: 'system',
          time_stamp: 1708191792
        },
        {
          content: 'Contact Information:',
          hide: false,
          platform: 'Visually',
          role: 'system',
          time_stamp: 1708191793
        },
        {
          content: "Hi there! I hope you've ",
          platform: 'SMS',
          role: 'assistant',
          time_stamp: 1708191852
        },
        {
          content: 'Chris,\nWe’ll share tw',
          platform: 'Email',
          role: 'assistant',
          time_stamp: 1708359429
        }
      ],
      email: 'chrisjabez@gmail.com',
      ghl_contact_id: 'zfGyFd4o8Xsk3rv0KCaO',
      ghl_contact_info: {},
      ghl_source: '4x',
      ghl_tags: [],
      id: 1,
      instagram_psid: null,
      last_contact: 1708359429,
      last_message: 'Chris,\nWe’ll share two case studies of compa',
      messenger_psid: null,
      name: 'chris guerrette',
      notes: 'api test',
      phone_number: '+18608822027',
      platforms: [],
      profile_pic: null,
      scheduled_event: [],
      scheduled_message: {},
      settings: [],
      state: null,
      time_ago: '5 months ago',
      whatsapp_number: null
    },
    message: 'Contact retrieved',
    success: true
  }
];
const contactPropTypes = PropTypes.shape({
  data: PropTypes.shape({
    AI_typing: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    active_agent_name: PropTypes.string,
    active_tools: PropTypes.arrayOf(PropTypes.string).isRequired,
    conversation: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        hide: PropTypes.bool.isRequired,
        platform: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        time_stamp: PropTypes.number.isRequired
      })
    ).isRequired,
    email: PropTypes.string.isRequired,
    ghl_contact_id: PropTypes.string.isRequired,
    ghl_contact_info: PropTypes.object.isRequired,
    ghl_source: PropTypes.string.isRequired,
    ghl_tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    instagram_psid: PropTypes.string,
    last_contact: PropTypes.number.isRequired,
    last_message: PropTypes.string.isRequired,
    messenger_psid: PropTypes.string,
    name: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    profile_pic: PropTypes.string,
    scheduled_event: PropTypes.arrayOf(PropTypes.object).isRequired,
    scheduled_message: PropTypes.object.isRequired,
    settings: PropTypes.arrayOf(PropTypes.object).isRequired,
    state: PropTypes.string,
    time_ago: PropTypes.string.isRequired,
    whatsapp_number: PropTypes.string
  }).isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired
});

const ContactsContext = createContext(defaultContacts);

export const useContacts = () => useContext(ContactsContext);

const ContactsProvider = ({ children }) => {
  const [errorResponse, setErrorResponse] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const hideErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [user] = useLocalStorage('user');
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const getContacts = async (arg, append = true) => {
    // let data = {filters: arg.payload};
    // setIsLoading(true);
    try {
      const response = await axiosRequest(
        'POST',
        `${config.apiUrl}contacts?limit=100`,
        null,
        {
          filters: arg.filters
        },
        navigate
      );
      if (response.success === true) {
        const newContacts = response.data;
        console.log(newContacts);
        setContacts(newContacts);
        // if (newContacts.length < 10) {
        //   setHasMore(false);
        // }
        // if (append) {
        //   setContacts((prevContacts) => [...prevContacts, ...newContacts]);
        // } else {
        //   setContacts(newContacts);
        // }
        setIsLoading(false);
        return response?.data;
      } else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const SendTrainingData = async ({ payload }) => {
    try {
      const response = await axiosRequest(
        'POST',
        `${config.apiUrl}agents/training_data`,
        null,

        payload,
        navigate
      );

      if (response.success === true) return response;
      else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchingContacts = async (page, append = true) => {
    setIsLoading(true);
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}contacts?limit=200&skip=${page}`, null, null);
      const newContacts = response?.data;

      if (newContacts?.length < 2) {
        setHasMore(false);
      }

      if (append) {
        const combinedContacts = [...contacts, ...newContacts];
        if (!isEqual(contacts, combinedContacts)) {
          setContacts(response?.data);
        }
      } else {
        if (!isEqual(contacts, newContacts)) {
          setContacts(response?.data);
        }
      }
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
    setIsLoading(false);
  };

  const liveChat = async (arg) => {
    setIsLoading(true);
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}live_chat/${arg.user_name}/${arg.agent_id}`, null, {
        messages: arg.messages
      });
      return response;
    } catch (error) {
      console.error('Failed to fetch chats', error);
    }
    setIsLoading(false);
  };

  const getContactById = async (arg) => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}contacts/${arg}`, null, null, navigate);

      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const scheduleMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/replace_scheduled_message`, null, arg.payload, navigate);
    if (data.success === true) {
      const index = contacts.findIndex((contact) => contact.id === arg.id);

      var tmp = [...contacts];

      tmp[index].scheduled_message = data?.data;
      console.log(tmp);
      setContacts(tmp);
      return data;
    } else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const sendScheduledMessageNow = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/send_scheduled_message_now`, null, null, navigate);

    if (data.success === true) {
      const index = contacts.findIndex((contact) => contact.id === arg.id);

      var tmp = [...contacts];

      tmp[index].scheduled_message = data;
      console.log(tmp);
      setContacts(tmp);
      return data;
    } else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const takeActionMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/AI/take_action`, null, arg.payload, navigate);
    if (data.success === true) {
      const index = contacts.findIndex((contact) => contact.id === arg.id);

      var tmp = [...contacts];

      tmp[index].scheduled_message = data;

      setContacts(tmp);
      return data;
    } else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const generateAiMessages = async (arg) => {
    const data = await axiosRequest(
      'GET',
      `${config.apiUrl}contacts/${arg.contact_id}/AI/get_draft`,
      { agent_id: arg.agent_id },
      null,
      navigate
    );
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const cancelScheduleMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg}/cancel_queued_messages`, null, null, navigate);
    if (data.success === true) {
      const index = contacts.findIndex((contact) => contact.id === arg);

      var tmp = [...contacts];

      tmp[index].scheduled_message = {};

      // tmp[index].scheduled_message = data?.data;
      // setContacts(tmp);

      console.log(index);
      console.log(tmp);

      return data;
    } else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const toggleAutoPilot = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/auto-pilot/state`, null, arg.payload);
    const index = contacts.findIndex((contact) => contact.id === arg.id);

    var tmp = [...contacts];

    tmp[index].active = arg.payload.state === true ? 1 : 0;

    setContacts(tmp);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const updateActiveAgent = async (arg) => {
    const data = await axiosRequest('put', `${config.apiUrl}contact/${arg.id}`, null, arg.payload);
    if (data.success === true) {
      const index = contacts.findIndex((contact) => contact.id === arg.id);

      var tmp = [...contacts];

      tmp[index].active_agent_name = arg.payload.active_agent_name;
      console.log(tmp[index]);
      setContacts(tmp);
      return data;
    } else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        setPage,

        page,
        liveChat,
        isLoading,
        fetchingContacts,
        SendTrainingData,
        contacts,
        setContacts,
        hasMore,
        getContactById,
        updateActiveAgent,
        toggleAutoPilot,
        cancelScheduleMesssage,
        generateAiMessages,
        scheduleMesssage,
        getContacts,
        takeActionMesssage,
        sendScheduledMessageNow
      }}
    >
      {children}
      {errorResponse ? <ErrorModal errorModalOpen={errorModalOpen} errorObject={errorResponse} onClose={hideErrorModal} /> : null}
    </ContactsContext.Provider>
  );
};

export { ContactsProvider, ContactsContext, contactPropTypes };
