import React, { useState } from 'react';
// import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import axios from 'axios';
import { Button, CircularProgress, Snackbar } from '@mui/material';
import VuiButton from 'components/atoms/VuiButton';

const PaymentForm = () => {
  // const stripe = useStripe();
  // const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      setSnackbarMessage('Payment successful!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Error processing payment');
      setSnackbarOpen(true);
    }

    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <CardElement /> */}
      {/* <VuiButton color="info" type="submit" size="small" variant="gradient" disabled={!stripe || loading} sx={{ mt: 2 }}>
        {loading ? <CircularProgress size={24} /> : 'Proceed'}
      </VuiButton> */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbarMessage} />
    </form>
  );
};

export default PaymentForm;
