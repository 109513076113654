import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, TextField, MenuItem, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ApexCharts from 'react-apexcharts';
import format from 'date-fns/format';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { UsersContext } from 'store/api/usersApi';

const AnalyticsChartNew = ({ setAgents,setSelectedAgent, agents,dailyActivity, performance }) => {
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date()
  });

  const {getAgents } = useContext(UsersContext);
  useEffect(() => {
   
    getAgents().then((res) => {
      console.log(res);
      setAgents(res);
    });
  }, []);
  const [dailyForecast, setDailyForecast] = useState(1);
  const [performaceForecast, setPerformaceForecast] = useState(3);

  const filterDataByDateRange = (data) => {
    return data?.filter((entry) => {
      const entryDate = new Date(entry.day);
      return entryDate >= dateRange.start && entryDate <= dateRange.end;
    });
  };

  const filteredDailyActivity = filterDataByDateRange(dailyActivity);
  const filteredPerformance = filterDataByDateRange(performance);

  const calculateEngagementRates = (performanceData, startDate, endDate) => {
    let totalEngagementRate = 0;
    let totalBookRate = 0;
    let totalReachOuts = 0;

    const filteredData = performanceData?.filter((dayData) => {
      const dayDate = new Date(dayData.day);
      return dayDate >= startDate && dayDate <= endDate;
    });

    for (const dayData of filteredData) {
      if (dayData.new_reach_outs) {
        totalEngagementRate += (dayData?.engagement_rate || 0) * dayData.new_reach_outs;
        totalBookRate += (dayData.book_rate || 0) * dayData.new_reach_outs;
        totalReachOuts += dayData.new_reach_outs;
      }
    }

    if (totalReachOuts === 0) {
      return { engagementRate: null, bookRate: null };
    }

    const averageEngagementRate = totalEngagementRate / totalReachOuts;
    const averageBookRate = totalBookRate / totalReachOuts;

    return {
      engagementRate: averageEngagementRate.toFixed(2),
      bookRate: averageBookRate.toFixed(2)
    };
  };

  const { engagementRate: avgEngagementRate, bookRate: avgBookRate } =
    performance && performance.length > 0
      ? calculateEngagementRates(performance, dateRange.start, dateRange.end)
      : { engagementRate: 'N/A', bookRate: 'N/A' };

  const totalNewLeads = filteredDailyActivity?.reduce((sum, item) => sum + (item.new_reach_outs || 0), 0);
  const totalInboundMessages = filteredDailyActivity?.reduce((sum, item) => sum + (item.inbound_messages || 0), 0);
  const totalBookedCalls = filteredDailyActivity?.reduce((sum, item) => sum + (item.booked_calls || 0), 0);
  const totalOptOuts = filteredDailyActivity?.reduce((sum, item) => sum + (item.opt_out_rate || 0), 0);

  const performanceSeries = [
    {
      name: 'Book Rate',
      data: filteredPerformance?.map((item) => item.book_rate || 0)
    },
    {
      name: 'Engagement Rate',
      data: filteredPerformance?.map((item) => item.engagement_rate || 0)
    },
    {
      name: 'Opt-outs',
      data: filteredPerformance?.map((item) => item.opt_out_rate || 0)
    }
  ];

  const dailyActivitySeries = [
    {
      name: 'Booked Calls',
      data: filteredDailyActivity?.map((item) => item.booked_calls || 0)
    },
    {
      name: 'Inbound Messages',
      data: filteredDailyActivity?.map((item) => item.inbound_messages || 0)
    },
    {
      name: 'New Leads Contacted',
      data: filteredDailyActivity?.map((item) => item.new_reach_outs || 0)
    },
    {
      name: 'Opt-outs',
      data: filteredDailyActivity?.map((item) => item.opt_out_rate || 0)
    }
  ];

  const dateLabels = filteredDailyActivity?.map((item) => format(new Date(item.day), 'MMM dd'));

  const performanceOptions = {
    chart: { type: 'line', height: 350, foreColor: '#FFFFFF' },
    xaxis: { categories: dateLabels },
    title: { text: 'Performance', align: 'left' },
    forecastDataPoints: {
      count: performaceForecast,
      dashArray: 8
    },
    tooltip: {
      style: {
        color: '#000000'
      },
      theme: 'dark'
    },
    stroke: {
      width: [3, 3, 3],
      dashArray: [0, 0, 5]
    }
  };

  const dailyActivityOptions = {
    chart: { type: 'line', height: 350, foreColor: '#FFFFFF' },
    xaxis: { categories: dateLabels },
    forecastDataPoints: {
      count: dailyForecast,
      fillOpacity: 0.5,
      dashArray: 8
    },
    tooltip: {
      style: {
        color: '#000000'
      },
      theme: 'dark'
    },
    stroke: {
      width: [3, 3, 3]
    },
    title: { text: 'Daily Activity', align: 'left' }
  };


  const handleSelectedAgent=(e)=>{
       
        setSelectedAgent(e.target.value)
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Analytics
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" gap={2} mb={10}>
            <DatePicker
              selected={dateRange.start}
              onChange={(date) => setDateRange((prev) => ({ ...prev, start: date }))}
              selectsStart
              startDate={dateRange.start}
              endDate={dateRange.end}
              customInput={<TextField label="Start Date" />}
            />
            <DatePicker
              selected={dateRange.end}
              onChange={(date) => setDateRange((prev) => ({ ...prev, end: date }))}
              selectsEnd
              startDate={dateRange.start}
              endDate={dateRange.end}
              minDate={dateRange.start}
              customInput={<TextField label="End Date" />}
            />

           <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Agents
              </InputLabel>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
                onChange={(e)=> handleSelectedAgent(e)}
              >
                <option key='all' value='all'>All</option>
                {agents?.map((agent, index)=>{
                      return(
                                
                    <option key={index} value={agent.id}>{agent.name}</option>
                      )
                })}
                </NativeSelect>
              </FormControl>
            </Box>
        </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: '#1E1E2F', color: '#FFFFFF' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Activity
              </Typography>
              <Box display="flex" justifyContent="space-around" my={2}>
                <Box textAlign="center">
                  <Typography variant="h4">{totalNewLeads}</Typography>
                  <Typography variant="subtitle2">New Leads Contacted</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h4">{totalInboundMessages}</Typography>
                  <Typography variant="subtitle2">Inbound Messages</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h4">{totalBookedCalls}</Typography>
                  <Typography variant="subtitle2">Booked Calls</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h4">{totalOptOuts}</Typography>
                  <Typography variant="subtitle2">Opt-outs</Typography>
                </Box>
              </Box>
              <ApexCharts options={dailyActivityOptions} series={dailyActivitySeries} type="line" height={300} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: '#1E1E2F', color: '#FFFFFF' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Performance
              </Typography>
              <Box display="flex" justifyContent="space-around" my={2}>
                <Box textAlign="center">
                  <Typography variant="h4">{avgEngagementRate}%</Typography>
                  <Typography variant="subtitle2">Engagement Rate</Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h4">{avgBookRate}%</Typography>
                  <Typography variant="subtitle2">Book Rate</Typography>
                </Box>
              </Box>
              <ApexCharts options={performanceOptions} series={performanceSeries} type="line" height={300} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsChartNew;
