import React, { useEffect } from 'react';
import { Stack, Grid, InputAdornment, Box, IconButton, Button, Typography, TextField } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import VuiBox from 'components/atoms/VuiBox';
import { useContext, useState } from 'react';
import Logo from 'assets/images/calendly-logo.png';

import { UsersContext } from 'store/api/usersApi';

const AccountSetting = () => {
  const CLIENT_ID = 'kESM7kZ3Ioj6Ol4rabWDuvB2Lupd2XkqKx8Y_l9wIeE';
  const REDIRECT_URI = 'https://app.steamlined.solutions/calendly/callback';

  const [clip, setClip] = useState('API Key');
  const { users } = useContext(UsersContext);
  useEffect(() => {
    console.log(users);
  }, []);
  const handleIntegrateGHL = () => {
    window.location.href =
      'https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.steamlined.solutions/GoHigh/callback&client_id=6578ad5753448863258292a8-ls1v3cak&scope=conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write calendars.readonly calendars.write opportunities.readonly opportunities.write medias.readonly businesses.readonly forms.readonly locations/customFields.readonly locations/tags.readonly locations/tags.write';
  };

  const handleIntegrateGoogleCalendar = () => {
    window.location.href =
      'https://accounts.google.com/o/oauth2/auth?client_id=347530803572-kgm7r0u7u86bar8icipiu3gjbv17c093.apps.googleusercontent.com&redirect_uri=https://app.steamlined.solutions/GoogleCalendar/callback&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email&access_type=offline';
  };

  const handleCopyClick = () => {
    setClip('Copied');
    navigator.clipboard
      .writeText(users?.token1)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  return (
    <VuiBox sx={{ p: 5 }}>
      <Stack direction="row" gap={3} justifyContent="center">
        <Typography sx={{ margin: 6, fontSize: '16px' }} variant="h3">
          Account Settings
        </Typography>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="h5" sx={{ padding: 3 }}>
            User Name
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5" sx={{ padding: 3 }}>
            {users?.user_name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" sx={{ padding: 3 }}>
            Api Key
          </Typography>
        </Grid>
        <Grid xs={8} item>
          <TextField
            onClick={handleCopyClick}
            label={clip}
            type="text"
            value={users?.token1}
            disabled
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopyClick} aria-label="copy" color="primary" disabled>
                    <FileCopy />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <VuiBox sx={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() =>
                (window.location.href = `https://calendly.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`)
              }
            >
              <div>
                <img
                  src={Logo}
                  alt="calendly"
                  style={{
                    width: '100%',
                    height: '24px',
                    marginRight: '8px'
                  }}
                />

                <Typography variant="body2">{users?.calendly_integrated ? 'already integrated' : 'integrate Calendly'}</Typography>
              </div>
            </Button>
          </VuiBox>
        </Grid>
        <Grid item xs={12}>
          <VuiBox sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => handleIntegrateGHL()}>
              <div>
                <Typography variant="subtitle1"> Integrate to Your Crm</Typography>
                <Typography variant="body2">{users?.gohighlevel_integrated ? 'already integrated' : ' integrate GHL'}</Typography>
              </div>
            </Button>
          </VuiBox>
        </Grid>
        <Grid item xs={12}>
          <VuiBox sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => handleIntegrateGoogleCalendar()}>
              <div>
                <Typography variant="subtitle1"> Integrate Your Google Calendar</Typography>
              </div>
            </Button>
          </VuiBox>
        </Grid>
        <Grid item xs={12}>
          <VuiBox sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={4}></Grid>
          </VuiBox>
        </Grid>
      </Grid>
    </VuiBox>
  );
};

export default AccountSetting;
