// CustomAlert.js
import React from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from 'hooks/useAlert';

const CustomAlert = () => {
  const { alert, closeAlert } = useAlert();

  return (
    <Snackbar
      id="customAlert"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={alert.open}
      autoHideDuration={3000}
      onClose={closeAlert}
    >
      <Alert
        severity={alert.severity}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={closeAlert}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
