import React from 'react';

const TextWithLinks = ({text,color = 'black'}) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = text !== undefined ? text.split(urlRegex) : undefined;

  const elements = parts?.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} key={index} rel="noopener noreferrer" target="_blank">
          {part}
        </a>
      );
    } else {
      return <span key={index}>{part}</span>;
    }
  });

  return <div style={{color: `${color}`}}>{elements}</div>;
};

export default TextWithLinks;
