// UserList.js
import React, { useEffect, useState } from 'react';
import { TableRow, TableBody, Box, Table, TableHead, IconButton, TableCell } from '@mui/material';

import { Paper, styled } from '@mui/material';

import { getRuntimeErrors } from 'store/api/supportTicket';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import VuiBox from 'components/atoms/VuiBox/index';
import { Card } from '../../../../node_modules/@mui/material/index';
import VuiTypography from 'components/atoms/VuiTypography/index';
import linearGradient from 'themes/functions/linearGradient';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CodeSnippet from 'components/CodeSnippets';
import CustomLoading from 'components/customLoading/CustomLoading';
import { formatDate, getFormatedDateTime } from 'utils/common-utils';

const RuntimeErrors = () => {
  const { grey } = colors;
  const navigate = useState();
  const [rows, setRows] = useState([]);
  const { borderWidth } = borders;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getRuntimeErrors(navigate).then((res) => {
      setLoading(false);

      setRows(res.data);

      // setRows(res.data.map((errorString) => JSON.parse(errorString)));
    });
  }, []);

  const { size, fontWeightBold } = typography;
  const { gradients } = colors;

  const columns = [
    { label: 'error', name: 'error', align: 'left' },
    { label: 'time', name: 'time', align: 'left' },
    { label: 'location', name: 'location', align: 'left' }
  ];
  const renderColumns = columns.map(({ name, label, align }, key) => {
    let pl;
    let pr;

    return (
      <VuiBox
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
        color="text"
        component="th"
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        key={name}
        opacity={0.7}
        textAlign={align}
      >
        {label?.toUpperCase()}
      </VuiBox>
    );
  });

  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
      <>
        <TableRow onClick={() => setIsExpanded(!isExpanded)} {...otherProps}>
          {children}
          <TableCell>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        {isExpanded ? <TableRow>{expandComponent}</TableRow> : null}
      </>
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card
            sx={{
              borderRadius: '30px',
              p: 5,
              background: linearGradient('gray', gradients.sidenav.state, gradients.sidenav.deg)
            }}
          >
            <VuiBox alignItems="center" display="flex" justifyContent="space-between" mb="22px">
              <VuiTypography color="white" variant="lg">
                Runtime Logs
              </VuiTypography>
            </VuiBox>
            {loading ? (
              <>
                <Card sx={{ height: '40vh' }}>
                  <CustomLoading message="Loading ..." />
                </Card>
              </>
            ) : (
              <VuiBox
                sx={{
                  '& th': {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) => `${borderWidth[1]} solid ${grey[700]}`
                  },
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({ borders: { borderWidth }, palette: { grey } }) => `${borderWidth[1]} solid ${grey[700]}`
                    }
                  }
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>{renderColumns}</TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((error, index) => (
                      <ExpandableTableRow
                        expandComponent={
                          <TableCell colSpan={5}>
                            <CodeSnippet jsonObject={error.details} />
                          </TableCell>
                        }
                        key={index}
                      >
                        <TableCell>
                          <VuiTypography color="white" fontWeight="medium" variant="button">
                            {error?.error}
                          </VuiTypography>
                        </TableCell>
                        <TableCell>
                          <VuiTypography color="white" fontWeight="medium" variant="button">
                            {getFormatedDateTime(error?.time)}
                          </VuiTypography>
                        </TableCell>

                        <TableCell>
                          <VuiTypography color="white" fontWeight="medium" variant="button">
                            {error?.location}
                          </VuiTypography>
                        </TableCell>
                      </ExpandableTableRow>
                    ))}
                  </TableBody>
                </Table>
              </VuiBox>
            )}
          </Card>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
};

export default RuntimeErrors;
