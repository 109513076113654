import { Box, Card, CardContent, Grid, keyframes, Typography } from '@mui/material';
import { BarChart, Phone, CheckCircle, Message, TrendingUp } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import VuiBox from 'components/atoms/VuiBox';
import { UsersContext } from 'store/api/usersApi';
import { useLoading } from 'hooks/useLoading';
import { background } from 'storybook/internal/theming';
import AnalyticsChart from './AnalyticsChart';
import { LucideSkull } from 'lucide-react';
import PerformanceChart from './Performance';
import AnalyticsChartNew from './AnalyticsChartNew';

const Analytics = () => {
  const [selectedAgent, setSelectedAgent] = useState();

  const [agents, setAgents] = useState([]);
  const { getAnalytics } = useContext(UsersContext);
  const [analytics, setAnalytics] = useState();
  const { showLoading, loading, closeLoading } = useLoading();
  useEffect(() => {
    showLoading('fetching analytics');
    let filter = 'all';
    if(selectedAgent == null)
    filter = 'all';
     else 
     filter = selectedAgent 
    getAnalytics(filter).then((res) => {
     
      setAnalytics(res);
      closeLoading();
    });
  }, [selectedAgent]);

  const totalAnalytics = analytics?.data?.total;
  const weeklyData = analytics?.data?.weeks?.map((week) => ({
    start: new Date(week.start * 1000).toLocaleDateString(),
    end: new Date(week.end * 1000).toLocaleDateString(),
    ...week.cohort_analytics
  }));

  const chartOptions = {
    chart: {
      id: 'weekly-analytics',
      type: 'line'
    },
    xaxis: {
      categories: weeklyData?.map((data) => `${data.start} - ${data.end}`)
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Weekly Cohort Analytics',
      align: 'left'
    }
  };

  const series = [
    {
      name: 'AI Booked',
      data: weeklyData?.map((data) => data.ai_booked)
    },
    {
      name: 'Engagements',
      data: weeklyData?.map((data) => data.engagements)
    },
    {
      name: 'Reached Outs',
      data: weeklyData?.map((data) => data.reached_outs)
    }
  ];

  return (
    <VuiBox sx={{ width: '100%', p: { xs: 0, lg: 5 } }}>
      {/* Total Analytics Section */}

      {/* Weekly Analytics Chart */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {/* <AnalyticsChart analytics={analytics?.data} /> */}
          <AnalyticsChartNew setSelectedAgent={setSelectedAgent} selectedAgent={selectedAgent} setAgents={setAgents} agents={agents}  dailyActivity={analytics?.data?.daily_activity} performance={analytics?.data?.performence} />
        </Grid>

        {/*  */}
      </Grid>
    </VuiBox>
  );
};

export default Analytics;
